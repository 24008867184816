// footer js
$(window).on('load resize',function(){
    var footerH = $("footer").outerHeight();
    $("#content").css('margin-bottom', footerH + 'px');
    $("footer").css({
        'position':'absolute',
				'width':'100%',
        'bottom':'0'
    });
	
		if ($(window).height() < 812) {
			$("footer").css({
					'position':'relative',
					'bottom':''
			});
		}
});
